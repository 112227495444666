import { render, staticRenderFns } from "./otpList.vue?vue&type=template&id=20b21237&scoped=true&"
import script from "./otpList.vue?vue&type=script&lang=js&"
export * from "./otpList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b21237",
  null
  
)

export default component.exports