import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: 'module.otp',
      hasWritePermission: (hasRole) : boolean => hasRole(['ROLE_ADMIN']),
      search: 'otp.search_keyword',
      filter: {
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      selectedData: row => ({
        id: row.id,
        name: row.name,
        title: row.title,
      }),
      displayMode: 'table',
      displayModeTypes: ['table'],
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      table: {
        data: <TableDataType>[
          {
            key: 'target',
            label: 'otp.data.target',
            type: 'text',
            copyable: true,
          },
          {
            key: 'code',
            label: 'otp.data.code',
            type: 'text',
            copyable: true,
          },
          {
            key: 'feature',
            label: 'otp.data.feature',
            type: 'text',
            text: row => this.vm?.$t(`otp.data.feature.${row.feature}`),
          },
          {
            key: 'ttl',
            label: 'otp.data.ttl',
            isHtml: true,
            text: row => `${row.ttl}${this.vm?.$t('unit.second')}<br>${row.expired_at}`,
            tooltipContent: () => null,
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
      batch: {
        delete: true,
      },
      dataAction: {
        delete: {
          create: (row: AnyObject, hasWritePermission: boolean) => hasWritePermission,
          removeTarget: row => row.name,
        },
      },
    }

  }
}

export default new listConfig()
