<template>
  <eagle-list
    :list-key="listKey"
  ></eagle-list>
</template>

<script>
import listMixin from '@/components/list/mixins/listMixin'
import listConfig from './otpListConfig'
export default {
  mixins: [listMixin],
  data: () => ({
    listKey: 'otp-list',
    meta: {},
  }),
  methods: {
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      return await this.$api.collection.otpApi.index(params)
    },
    async patchApi(value, row, config) {
      const target = row.id
      const column = config.key
      return await this.$api.collection.otpApi.patch(target, column, value)
    },
    async deleteApi(target) {
      return await this.$api.collection.otpApi.delete(target)
    },
    async batchApi(targets, column, value) {
      return await this.$api.collection.otpApi.batch(targets, column, value)
    },
    async batchDeleteApi(targets) {
      return await this.$api.collection.otpApi.batchDelete(targets)
    },
    getListConfig() {
      return listConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
